import { isAfter } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { CloseX, Warning } from "../../../../assets/svgs/index";
import { SlideContext } from "../../../../context/SlideContext";
import { UserContext } from "../../../../context/UserContext";
import {
  getDayOfTheWeekDayMonthYear,
  getHoursAndMinutes,
  addMinutes,
  getHourDifference,
  getMinuteDifference,
} from "../../../../utils/dates";
import { PurpleButton } from "../../Form/index";
import DrawerDetailsCancel from "../../Slide/DrawerDetailsCancel/DrawerDetailsCancel";
import "./DrawerDetailsClass.css";

const DrawerDetailsClass = ({ appointment, getAppointments }) => {
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { user } = useContext(UserContext);

  const handleChangeSlideClick = (component) => {
    setSlideComponent(component);
  };

  const calculateCancelClasse = (date) => {
    return getHourDifference(zonedTimeToUtc(date, user.timezone).toISOString()) >= 3;
  };

  const isButtonDisabled = () => {
    return (
      getMinuteDifference(appointment.schedule.dateTime) >= 9 ||
      isAfter(new Date(), addMinutes(appointment.schedule.dateTime, 50)) ||
      appointment.status === "MISSED"
    );
  };

  return (
    <div className="h-full flex flex-col w-full md:w-[380px]">
      <div>
        <header className="flex flex-row justify-between items-center">
          <h1 className="text-2xl">Detalhes da aula</h1>

          <CloseX className="cursor-pointer" onClick={() => setShowSlide(false)} />
        </header>
        <div className="flex flex-col sm:flex-row mt-8 sm:justify-between sm:items-center">
          <div className="flex flex-row mr-4 items-center">
            <img
              className="w-11 h-11 rounded-3xl"
              src={appointment.schedule.teacher.user.profilePictureUrl ?? process.env.REACT_APP_PROFILE_AVATAR_URL}
              alt="foto do professor"
            />

            <div className="ml-4 items-center">
              <h2 className="text-base mt-2">{appointment.schedule.teacher.user.name}</h2>
              <Link to={`professores/perfil/${appointment.schedule.teacher.uuid}`}>
                <button
                  type="button"
                  className="flex justify-center items-center rounded-lg font-semibold text-sm tracking-wider text-purple-900">
                  Ver Perfil
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <p className="text-sm capitalize">{getDayOfTheWeekDayMonthYear(appointment.schedule.dateTime)}</p>
          <p className="font-nunito text-xs font-bold tracking-wider uppercase text-gray-600">
            das {getHoursAndMinutes(appointment.schedule.dateTime)} ás{" "}
            {getHoursAndMinutes(addMinutes(appointment.schedule.dateTime, appointment.type))}
          </p>

          <PurpleButton
            className="mt-6 buttonLesson"
            disabled={isButtonDisabled()}
            onClick={() => {
              window.open(appointment.userMeetingUrl, "_blank");
            }}>
            Entrar na aula
          </PurpleButton>
        </div>
      </div>
      {/* <div>
        <div className="mt-6 rounded-2xl">
          <h1>Notificações</h1>
          <div className="notificationArea mt-2 flex flex-row items-center justify-center p-4">
            <div className="p-2 rounded-xl bellBackground">
              <Bell />
            </div>
            <p className="ml-7 text-gray-900">Não perca nenhuma aula, ative suas notificações</p>
            <DownChevron />
          </div>
        </div>
      </div> */}
      <div className="mt-5 flex-1">
        <div className="flex items-center">
          <div>
            <Warning style={{ fill: "#F59300" }} />
          </div>
          <p className="warningTextColor ml-4 text-xs sm:text-sm">
            Lembre-se: É possível cancelar uma aula até <span className="font-bold">3 horas</span> antes do seu início,{" "}
            <span className="font-bold">caso contrário a aula será contabilizada</span> como uma aula ocorrida.
          </p>
        </div>
      </div>
      {calculateCancelClasse(appointment.schedule.dateTime) && (
        <div className="borderButtons pt-4 flex">
          <button
            onClick={() =>
              handleChangeSlideClick(
                <DrawerDetailsCancel getAppointments={getAppointments} appointment={appointment} />,
              )
            }
            className="cancelLesson pt-4 pb-4 pr-18 pl-18 rounded-lg tracking-wider text-sm font-semibold w-60 h-14">
            Cancelar aula
          </button>
        </div>
      )}
    </div>
  );
};

DrawerDetailsClass.propTypes = {
  appointment: PropTypes.object.isRequired,
  getAppointments: PropTypes.func,
};

export default DrawerDetailsClass;
