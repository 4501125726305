import { isAfter } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { CloseX, Warning } from "../../../assets/svgs/index";
import DrawerProfile from "../../../components/Pages/Teacher/Classes/DrawerProfile";
import { ToastContext } from "../../../context/ToastContext";
import useRequest from "../../../hooks/useRequest";
import {
  getDayOfTheWeekCompletedDayMonthYear,
  getHoursAndMinutes,
  addMinutes,
  getMinuteDifference,
} from "../../../utils/dates";
import { PurpleButton } from "../Form/index";

const DrawerLessonTeacher = ({ setShowSlide, setSlideComponent, appointment, refresh }) => {
  const { request } = useRequest();
  const { t } = useTranslation();
  const { showToast } = useContext(ToastContext);

  const handleCancelLesson = async () => {
    await request("DELETE", `/appointment/cancel/${appointment.uuid}`);

    setShowSlide(false);
    refresh();
  };

  const handleComponent = async (component) => {
    setSlideComponent(component);
    setShowSlide(true);
  };

  const copyStudentLink = () => {
    showToast({ message: "Student link successfully copied" });
    navigator.clipboard.writeText(appointment.userMeetingUrl);
  };

  const isButtonDisabled = () => {
    return (
      getMinuteDifference(appointment.schedule.dateTime) >= 9 ||
      isAfter(new Date(), addMinutes(appointment.schedule.dateTime, 50)) ||
      appointment.status === "MISSED"
    );
  };

  return (
    <div
      className="h-full flex flex-col"
      style={{
        width: "34.5rem",
      }}>
      <div>
        <header className="flex flex-row justify-between items-center">
          <h1 className="text-xl">Detalhes da aula</h1>

          <CloseX className="cursor-pointer" onClick={() => setShowSlide(false)} />
        </header>
        <div className="flex flex-row mt-8 justify-between">
          <div className="flex flex-row">
            <img
              className="w-11 h-11 rounded-3xl"
              src={
                appointment?.user?.profilePictureUrl
                  ? appointment?.user?.profilePictureUrl
                  : process.env.REACT_APP_PROFILE_AVATAR_URL
              }
              alt="foto do professor"
            />
            <div className="ml-4 flex items-center justify-center">
              <h2
                className="text-base"
                style={{
                  color: "#1A1738",
                }}>
                {appointment?.user?.name}
              </h2>
            </div>
          </div>

          <button
            className="font-serif"
            onClick={() => {
              handleComponent(<DrawerProfile setShowSlide={setShowSlide} uuid={appointment.user.uuid} />);
            }}>
            Ver perfil do aluno
          </button>
        </div>
        <div className="mt-5">
          <p className="text-sm capitalize">{getDayOfTheWeekCompletedDayMonthYear(appointment.schedule.dateTime)}</p>
          <p className="font-nunito text-xs font-bold tracking-wider uppercase text-gray-600 mt-1">
            das {getHoursAndMinutes(appointment.schedule.dateTime)} ás{" "}
            {getHoursAndMinutes(addMinutes(appointment.schedule.dateTime, appointment.type))}
          </p>
        </div>
      </div>
      <div className="mt-4 flex-1">
        <div className="flex flex-col">
          <h1
            className="text-base"
            style={{
              color: "#383743",
            }}>
            Comentário do aluno
          </h1>

          <p
            className="mt-4 text-sm"
            style={{
              color: "#383743",
            }}>
            {appointment?.studentNote ? appointment?.studentNote : t("no_notes")}
          </p>

          <div className="flex space-x-4">
            <PurpleButton
              className="mt-6 buttonLesson"
              disabled={isButtonDisabled()}
              onClick={() => {
                window.open(appointment.teacherMeetingUrl, "_blank");
              }}>
              Join class
            </PurpleButton>

            <PurpleButton className="mt-6 buttonLesson" disabled={isButtonDisabled()} onClick={copyStudentLink}>
              Copy student link
            </PurpleButton>
          </div>

          <div className="flex items-center mt-5">
            <div>
              <Warning style={{ fill: "#F59300" }} />
            </div>
            <p className="warningTextColorCancel ml-4 text-sm">
              Lembre-se: Ao cancelar uma aula até 3h antes de seu inicio, o professor irá pagar uma multa de U$$ 3,50.
            </p>
          </div>
        </div>
      </div>
      <div className="borderButtonsTop pt-6 flex">
        <button
          className="cancelLessonCancel text-center rounded-lg tracking-wider text-sm font-semibold w-60 h-12"
          onClick={handleCancelLesson}>
          Cancelar aula
        </button>
      </div>
    </div>
  );
};

DrawerLessonTeacher.propTypes = {
  setShowSlide: PropTypes.func,
  setSlideComponent: PropTypes.func,
  appointment: PropTypes.object,
  refresh: PropTypes.func,
};

export default DrawerLessonTeacher;
